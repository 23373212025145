.navbar{
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;
  max-width: 100vw;
  width: 100%;
  position: fixed;
  z-index: 10;
  overflow: hidden;
  @media (max-width: $breakpoint-mobile) {
    font-size: 0.5rem;
    padding: 1em 0;
  }
  &__menu{
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }
    & a{
      color: $colour-text;
      text-decoration: none;
    }
    &Item{
      z-index: 2;
      transition: transform 200ms ease-in-out, scale 200ms ease-in-out;
      &:hover, &:focus{
        cursor: pointer;
        transform: translateY(-5px);
        scale: 1.2;
        font-weight: 800;
      }
    }
  }
  &--scrolled{
    @include glassEffect;
    border-radius: 0;
  }
}