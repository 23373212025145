.socials {
  margin-top: -50px;
  margin-bottom: 50px;
  max-height: 5vw;
  position: sticky;
  z-index: 5;
  top: 20%;
  left: 100%;
  scale: 0.75;
  transition: scale ease-in-out 200ms;
  &:hover, &:focus{
    scale: 1.25;
  }
  @media (max-width: $breakpoint-mobile) {
    scale: 1;
    font-size: 10px;
    margin-top: -100px;
    left: 100%;
  }
}

@keyframes bounce2 {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-12px);
  }
  40% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(30deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.socials-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: fit-content;
  padding: 0.5em;

}

.social-list {
  @media (max-width: 737px) {
    width: 60vw;
  }
  &__link {
    color: $colour-primary;
    font-size: 1.5em;
    font-weight: 800;
    padding: 0.5em;
    width: 100%;
    height: 100%;
  }
  &__icon:hover,
  &__icon:active {
    animation: bounce2 2000ms ease infinite;
    cursor: pointer;
  }
  &__item {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
