.tag{
    max-width: 100%;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 0.8125rem;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 32px;
    color: $colour-text;
    border-radius: 16px;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    outline: 0px;
    text-decoration: none;
    padding: 0px;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid rgb(229, 231, 235);
    font-weight: 500;
    margin: 4px;
    &__text{
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 12px;
        padding-right: 12px;
        white-space: nowrap;
    }
    @media (max-width: $breakpoint-mobile) {
        font-size: 0.65rem;
    }
}