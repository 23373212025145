.servicesCard {
  background-color: rgb(255, 255, 255);
  color: rgb(17, 25, 39);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px;
  background-image: none;
  overflow: hidden;
  border-radius: 20px;
  height: 100%;
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 25%;
  min-height: 968px;
  min-width: 285px;
  @media (max-width: 1440px) {
    max-width: 60%;
    margin: 1em 1em;
  }
  @media (max-width: $breakpoint-mobile) {
    min-width: 0;
    max-width: 60%;
    margin: 1em 0;
    min-height: 0;
  }

  &__container {
    display: flex;
    justify-content: space-evenly;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      align-items: center;
    }
    max-width: 100vw;
  }
  &__text {
    text-align: center;
    z-index: 2;
  }
  &__image {
    width: 90%;
    z-index: 2;
    margin: 2em 0;
    aspect-ratio: 1/1;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }

  }
  &__button {
    max-width: 150px;
    padding: 0.5em 1em;
    text-align: center;
    color: white;
    text-align: center;
    text-decoration: none;
    @media (max-width: 1440px) {
      padding: 0.5em;
    }

    &Container {
      z-index: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-self: center;
      justify-self: center;
      @media (max-width: $breakpoint-mobile){
        display: flex;
        justify-content: center;
        width: 80%;
      }
      @media (max-width: 1280px) {
        align-items: center;
        flex-direction: column;
        gap: 0.5em;
      }
    }
    @media (max-width: $breakpoint-mobile) {
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  
}