//Colour Palette Monochrome
$colour-primary:  #48517A;
$colour-secondary: #131520;
$colour-accent:  #5E6BA1;
$colour-text:  #E5E7F0;
$colour-dark:  #1b1b1b;
$colour-background: #000000;

//Colour Palette Red
$colour-primary:  #EB883D;
$colour-secondary: #3f0307;
$colour-accent:  #b9313a;
$colour-text:  #ffffff;
$colour-dark:  #1b1b1b;
$colour-background: #170204;
$colour-backgroundFX: hsla((random() * 100), 100%, 50%,.9);
$colour-backgroundFXv1: 100;
$colour-backgroundFXv2: 0;

// Colour Palette Green
$colour-primary:  #24620e;
$colour-secondary: #0f3701;
$colour-accent:  #56e024;
$colour-text:  #fcfefb;
$colour-dark:  #1b1b1b;
$colour-background: #0c1f04;
$colour-backgroundFX: hsla((random() * 200) + 50, 100%, 50%,.9);
$colour-backgroundFXv1: 200;
$colour-backgroundFXv2: 50;

// Colour Palette Blue
$colour-primary:  #5931dd;
$colour-secondary: #022631;
$colour-accent:  #6c56b3;
$colour-text:  #cef2fd;
$colour-text:  #ffffff;
$colour-dark:  #1b1b1b;
$colour-background: #01181e;
$colour-backgroundFX: hsla((random() * 100) + 200, 100%, 50%,.9);
$colour-backgroundFXv1: 100;
$colour-backgroundFXv2: 200;




//Breakpoints
$breakpoint-tablet: 1440px;
$breakpoint-mobile: 767.98px;

//Box Shadows

$bs-colour-accent: 0 0 20px $colour-accent;

//Typography

$ff-primary: 'Poppins';


@import url('https://fonts.googleapis.com/css?family=Poppins:700|Poppins:400');

body {
  font-family: 'Poppins';
  font-weight: 400;
}

h1, h2, h3, h4, h5 {
  font-family: 'Poppins';
  font-weight: 700;
}

html {font-size: 100%;} /* 16px */

h1 {font-size: 4.210rem; /* 67.36px */}

$fs-h1: 4.210rem;
$fs-mobile-h1: 2.369rem;

h2 {font-size: 2.369rem; /* 50.56px */}

$fs-h2: 3.158rem;
$fs-mobile-h2: 1.777rem;

h3 {font-size: 2.369rem; /* 37.92px */}

$fs-h3: 2.369rem;
$fs-mobile-h3: 1.333rem;

h4 {font-size: 1.777rem; /* 28.48px */}

$fs-h4: 1.777rem;
$fs-mobile-h4: 1.333rem;

h5 {font-size: 1.333rem; /* 21.28px */}

$fs-h5: 1.333rem;
$fs-mobile-h5: 0.750rem;

small {font-size: 0.750rem; /* 12px */}

@mixin glassEffect{
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.9px);
  -webkit-backdrop-filter: blur(3.9px);
}

@media (max-width: $breakpoint-mobile){
  h1 {font-size: $fs-mobile-h1;}
  h2 {font-size: $fs-mobile-h2;}
  h3 {font-size: $fs-mobile-h3;}
  h4 {font-size: $fs-mobile-h4;}
  h5 {font-size: $fs-mobile-h5;}
  .body{
    font-size: 16px;
  }
}