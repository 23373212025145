.homepage{
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  &__experience{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__about{
    background-color: $colour-background;
    z-index: 1;
    padding: 2em 0;
    min-height: 40vh;
    width: 100%;
    &Text{
      width: 80%;
      font-size: $fs-h4;
      text-align: center;
      &-content{
        @media(max-width: $breakpoint-mobile){
          font-size: $fs-mobile-h5;
        }
      }
    }

    @media (max-width: $breakpoint-mobile) {
      margin: 2em 0;
    }
  }
  &__contact{
    z-index: 1;
    margin-bottom: 4em;
    }
  
  &__projects{
    margin: 4em 0;
    z-index: 1;
    background-color: $colour-background;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.hero{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: auto;
  width: 90%;
  height: 90vh;
  z-index: 1;
  overflow: hidden;
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    height: 100vh;
    margin: 0;
    width: 100%;
  }
  &__image{
    border-radius: 50%;
    width: 95%;
    height: 95%;

    &Container{
      border-radius: 50%;
      width:228px;
      height: 228px;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
    }
  }
  &__hello{
    @media (max-width: $breakpoint-mobile) {
      margin: 1em .5em 0.25em .5em;
    }
  }
  &__intro{
    @media (max-width: $breakpoint-mobile) {
      margin: .25em 1em;
    }
  }

}

.projects__container{
  min-height: 800px;
  width: 90%;
  gap: 4em 2em;
  overflow: hidden;
  margin: 2em 1em;
  padding: 0 2em;
  
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-flow: wrap;
  @media (max-width: $breakpoint-mobile){
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    padding: 0;
    margin: 0 1em 2em 1em;
  }
}

.section__title{
  margin: 2em;
  z-index: 1;
  @media (max-width: $breakpoint-mobile){
    margin: 1em 0;
  }
}

.contact{
  &__text{
    @media (max-width: $breakpoint-mobile) {
      width: 80%;
    }
  }
}