.form{
  &__container{
    width: 60%;
  }
  &__input{
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.4375em;
      color: $colour-text;
      padding: 25px 12px 8px;
      box-sizing: border-box;
      font-family: monospace;
      cursor: text;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      width: 100%;
      position: relative;
      background-color: transparent;
      border-radius: 8px;
      border-style: solid;
      border-width: 1px;
      overflow: hidden;
      transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-color: rgb(229, 231, 235);
      &:focus{
      }
  }
  &__largeInput{
    height: 200px;
  }
  &__label{
      color: $colour-text;
      line-height: 1.4375em;
      padding: 0px;
      font-size: 14px;
      font-weight: 500;
      display: block;
      transform-origin: left top;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 24px);
      position: relative;
      left: 0px;
      top: 10px;
      transform: translate(12px, 16px) scale(1);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      z-index: 1;
      pointer-events: none;
      &:focus{
        transform: translate(12px, 6px) scale(0.85);
      }
  }
}