.languageSelector{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4375em;
    color: $colour-text;
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 120px;
    position: relative;
    background-color: $colour-secondary;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-color: rgb(229, 231, 235);
    margin: 0 2em;
    &:hover{
      cursor: pointer;
    }
    @media (max-width: $breakpoint-mobile) {
      font-size: 0.5rem;
      width: 70px;
      margin: 0 .5em;
    }
}