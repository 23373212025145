html,
body{
  background-color: $colour-background;
  color: $colour-text;
  scroll-behavior: smooth;
  font-size: 20px;
}
section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@mixin dots($count) {
  $text-shadow: ();
  @for $i from 0 through $count {
    $text-shadow: $text-shadow,
                 (-.5+(random()) * 3) + em
                 (-.5+(random()) * 3) + em
                 7px
                 hsla((random() * $colour-backgroundFXv1) + $colour-backgroundFXv2, 100%, 50%,.9)
  }
  text-shadow: $text-shadow;
}

html {

  background: #123;
}

.backgroundFX {
  display: flex;
  font-size: 52px;
  color: transparent;
  z-index: 0;
  @media (max-width: $breakpoint-mobile){
    width: 100vw;
  }
}

.backgroundFX::before, .backgroundFX::after {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 3em;
  height: 3em;
  content: '.';
  mix-blend-mode: screen;
  animation: 44s -27s move infinite ease-in-out alternate;
}


.backgroundFX::before {
  @include dots(40);
  animation-duration: 44s;
  animation-delay: -27s;
}

.backgroundFX::after {
  @include dots(40);
  animation-duration: 43s;
  animation-delay: -32s;
}


@keyframes move {
  from {
    transform: rotate(0deg) scale(12) translateX(-20px);
  }
  to {
    transform: rotate(360deg) scale(18) translateX(20px);
  }
}

@media (max-width: $breakpoint-mobile){
  .App{
    width: 100vw;
    text-align: center;
  }
}